import { inject, observer } from 'mobx-react';
import { createContext } from 'react';

// Context per la gestione di currency dinamiche
export const CurrencyContext = createContext();

const CurrencyProvider = inject('ProjectStore')(
  observer(({ ProjectStore, children }) => {
    return (
      <CurrencyContext.Provider
        value={{
          locale: ProjectStore.locale || 'it-IT',
          currency: ProjectStore.currency || 'EUR',
        }}
      >
        {children}
      </CurrencyContext.Provider>
    );
  }),
);

export default CurrencyProvider;
