import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ALL_FONTS } from 'constants/Fonts';

const CustomStyleGenerator = inject('ProjectStore')(
  observer(({ ProjectStore }) => {
    const [settings] = useState(ProjectStore.getSettings);

    const primaryFont = settings && settings.primaryFont ? ALL_FONTS.find((font) => font.name === settings.primaryFont) : null;

    const primary = settings.primaryColor;
    const secondary = settings.secondaryColor;
    const contrastPrimary = settings.contrastPrimaryColor;
    const contrastSecondary = settings.contrastSecondaryColor;

    const primaryContent = primary
      ? ` 
        .primaryBackgroundColor {
          background-color: ${primary}!important;
          ${contrastPrimary ? `color: ${contrastPrimary}!important; ` : ''}
        }
        .primaryBackgroundColor20p {
          background-color: ${primary}33!important;
        }
        .primaryBorderColor {
            border-color: ${primary}!important;
        }
        .primaryOutlineColor {
          outline-color: ${primary}!important;
        }
        .primaryFontAndBorderColor {
          color: ${primary}!important;
          border-color: ${primary}!important;
        }
        .primaryBackgroundAndBorderColor {
            background-color: ${primary}!important;
            border-color: ${primary}!important;
            ${contrastPrimary ? `color: ${contrastPrimary}!important;` : ''}
        }
        .primaryTextColor {
            color: ${primary}!important;
        }
        .save-configuration-modal-input:focus {
          border-color: ${primary}
        }
        .save-configuration-back-button {
          border-color: ${primary};
          ${contrastPrimary ? `background-color: ${contrastPrimary}` : ''}
        }
        .save-configuration-save-button {
          background-color: ${primary};
          ${
            contrastPrimary
              ? `
            border-color: ${contrastPrimary};
            color: ${contrastPrimary};
          `
              : ''
          }
        }
        .hc--breadcrumb-item.active::before {
          background-color: ${primary}!important;
        }        
      `
      : '';

    const primaryFontContent = primaryFont
      ? primaryFont.types.map((type) => {
          const fontStyle = type.style.fontStyle;
          const fontWeight = type.style.fontWeight;
          return `
            @font-face {
              font-family: "primary-font";
              src: url(${process.env.REACT_APP_BUCKET_BASEURL}${type.path});
              ${type.style && fontStyle ? `font-style: ${fontStyle};` : ''}
              ${type.style && fontWeight ? `font-weight: ${fontWeight};` : ''}
            }
          `;
        })
      : '';

    const secondaryContent = secondary
      ? ` 
      .secondaryBackgroundColor {
          background-color: ${secondary}!important;
          ${contrastSecondary ? `color: ${contrastSecondary}!important;` : ''}
      }
      .secondaryBorderColor {
          border-color: ${secondary}!important;
      }
      .secondaryBackgroundAndBorderColor {
          background-color: ${secondary}!important;
          border-color: ${secondary}!important;
          ${contrastSecondary ? `color: ${contrastSecondary}!important;` : ''}
      }
      .secondaryOutlineColor {
        outline-color: ${secondary}!important;
      }
      .secondaryTextColor {
        color: ${secondary}!important;
      }   
      `
      : '';

    return settings ? (
      <style>
        {primaryContent}
        {secondaryContent}
        {primaryFontContent}
      </style>
    ) : null;
  }),
);

export default CustomStyleGenerator;
