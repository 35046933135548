import { createLogger, transports, format } from 'winston';
import TecmaTransport from 'logger/TecmaTransport';

const logger = createLogger({
  level: process.env.REACT_APP_LOGGING_LEVEL,
  format: format.combine(format.colorize(), format.simple()),
  transports: [new transports.Console({}), new TecmaTransport({ level: 'error' })],
});

export default logger;
