import React from 'react';
import { observer } from 'mobx-react';
import AppartmentSurfaces from 'constants/AppartmentSurfaces';

const Icons = observer(() => {
  const icons = [
    {
      name: 'arrow-left',
      icon: 'arrow-left.svg',
    },
    {
      name: 'arrow-right',
      icon: 'arrow-right.svg',
    },
    {
      name: 'arrow-down',
      icon: 'arrow-down.svg',
    },
    {
      name: 'check-outline',
      icon: 'check-outline.svg',
      color: 'white',
    },
    {
      name: 'check-outline-2',
      icon: 'check-outline.svg',
      noMask: true,
    },
    {
      name: 'close',
      icon: 'close.svg',
    },
    {
      name: 'phone',
      icon: 'phone.svg',
    },
    {
      name: 'email',
      icon: 'email.svg',
    },
    {
      name: 'info_circle',
      icon: 'info_circle.svg',
    },
    {
      name: 'info_circle_white',
      icon: 'info_circle_white.svg',
      color: 'white',
    },
    {
      name: 'plus_white',
      icon: 'plus_white.svg',
      color: 'white',
    },
    {
      name: 'close_white',
      icon: 'close_white.svg',
      color: 'white',
    },
    {
      name: 'edit',
      icon: 'edit.svg',
    },
    {
      name: 'arrow-down',
      icon: 'arrow-down.svg',
    },
    {
      name: 'info_circle_white',
      icon: 'info_circle_white.svg',
      color: 'white',
    },
    {
      name: 'globe',
      icon: 'globe.svg',
    },
    {
      name: 'question',
      icon: 'question.svg',
    },
    {
      name: 'sign_out',
      icon: 'sign_out.svg',
    },
    {
      name: 'finish',
      icon: 'finish.svg',
    },
    {
      name: 'fullscreen-off',
      icon: 'fullscreen-off.svg',
      noMask: true,
    },
    {
      name: 'fullscreen-on',
      icon: 'fullscreen-on.svg',
      noMask: true,
    },
    {
      name: 'arrow-left-slider',
      icon: 'arrow-left-slider.svg',
      noMask: true,
    },
    {
      name: 'arrow-right-slider',
      icon: 'arrow-right-slider.svg',
      noMask: true,
    },
    {
      name: 'pencil',
      icon: 'pencil.svg',
    },
    {
      name: 'pencil-alt',
      icon: 'pencil-alt.svg',
    },
    {
      name: 'trash',
      icon: 'trash.svg',
    },
  ];

  const containers = ['home-configurator-container', 'page-404-wrapper'];

  return (
    <style>
      {/* Generic icons */}
      {icons.reduce((res, i) => {
        const containerClasses = containers.map((c) => `.${c} .icon.${i.name}`);
        const url = `url('${process.env.REACT_APP_BUCKET_BASEURL}/HomeConfigurator/img/icon/${i.icon}');`;
        let css;
        if (!i.noMask)
          css = `
            ${containerClasses.join(',')} {
              background-color: ${i.color ? i.color : 'black'};
              -webkit-mask-image: ${url}
              mask: ${url}
              -webkit-mask-repeat: no-repeat;
              -webkit-mask-position: center;
              ${i.customCSS ? i.customCSS : ''}
            }
          `;
        else
          css = `
            ${containerClasses.join(',')} {
              background-image: url('${process.env.REACT_APP_BUCKET_BASEURL}/HomeConfigurator/img/icon/${i.icon}');
              ${i.customCSS ? i.customCSS : ''}
            }
          `;
        return res.concat(css);
      }, [])}
      {[...AppartmentSurfaces, 'piano'].map((supLabel) => {
        const supKey = supLabel.replace(' ', '');
        const containerClasses = containers.map((c) => `.${c} .icon.ico-sup-${supKey}`);

        return `
            ${containerClasses.join(',')} {
              background-image: url('${process.env.REACT_APP_BUCKET_BASEURL}/global/img/icon/ico__services--${supKey}.svg');
            }
          `;
      })}
    </style>
  );
});

export default Icons;
