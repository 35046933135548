import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { datadogRum } from '@datadog/browser-rum';

import CookiesUtils from 'utils/cookiesUtils';
import 'SCSS/App.scss';
import { useLazyQuery, useQuery } from '@apollo/client';
import GRAPHQL from 'graphql/GraphQLEndpoints';
import { DetectUrl } from 'utils/DetectUrl';
import { modifyTitle, loadFavicon, loadCss } from 'utils/DOMInjector';
import { buildToolLink, getQSParameterValue } from 'utils/utils';
import ErrorPage from 'components/common/ErrorPage';
import RoutesInitializer from 'components/RoutesInitializer';
import { runInAction } from 'mobx';
import { ErrorBoundary } from 'react-error-boundary';
import { loadResources } from '@tecma/i18n';
import Loader from 'components/common/Loader';
import CurrencyProvider from 'contexts/CurrencyProvider';
import inizializeAnalytics from 'utils/analytics';
import ServiceWorkerWrapper from 'components/common/ServiceWorkerWrapper';

if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'homeconfigurator',
    env: process.env.REACT_APP_NAMESPACE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

const App = inject(
  'ProjectStore',
  'QuoteStore',
  'ApartmentStore',
  'ClientStore',
  'LanguageStore',
)(
  observer(({ ProjectStore, QuoteStore, ApartmentStore, ClientStore, LanguageStore }) => {
    const [initializedApartment, setInitializedApartment] = useState(false);
    const [initializedLanguage, setInitializedLanguage] = useState(false);

    const LOADER = <Loader className='button-loader' />;

    const backToMyHome = useCallback(() => {
      const MY_HOME_LINK = buildToolLink('hc', 'myhome', ProjectStore.getHost, ProjectStore.getSite('MyHome'));
      window.location.assign(MY_HOME_LINK);
    }, [ProjectStore]);

    const quoteId = getQSParameterValue('quoteId');

    const { data, loading, called, error } = useQuery(GRAPHQL.GET_PROJECT_INFO, GRAPHQL.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl(ProjectStore)));
    const [loadQuoteData, quoteDataQuery] = useLazyQuery(GRAPHQL.GET_QUOTE);
    const [loadApartmentInfo, apartmentInfo] = useLazyQuery(GRAPHQL.GET_APARTMENT);

    useEffect(() => {
      if (!loading && called && data?.getProjectInfoByHost) {
        const project = data.getProjectInfoByHost;

        let dbLanguages = ['it-IT', 'en-GB'];
        if (data.getProjectInfoByHost.homeConfiguratorConfig?.defaultLanguages) {
          dbLanguages = data.getProjectInfoByHost.homeConfiguratorConfig?.defaultLanguages;
        }

        runInAction(() => {
          ProjectStore.setProject(project);
          LanguageStore.setDBLanguages(dbLanguages);
        });

        modifyTitle(`${project.displayName} | Home-Configurator`);
        loadFavicon(project.displayName);
        loadCss(project.displayName);

        loadResources({
          id: project.id,
          displayName: project.displayName,
          languages: dbLanguages,
        }).finally(() => {
          setInitializedLanguage(true);
        });

        if (quoteId !== null) {
          if (quoteId !== QuoteStore.getQuoteId) {
            QuoteStore.setQuoteData(null);
          }
          QuoteStore.setQuoteId(quoteId);
        }

        if (CookiesUtils.getClientId())
          runInAction(() => {
            ClientStore.setClientId(CookiesUtils.getClientId());
          });

        // Controllo che il clientId e il quoteId ci siano
        if (!QuoteStore.getQuoteId) backToMyHome();
        else if (QuoteStore.getQuoteId) loadQuoteData(GRAPHQL.GET_QUOTE_OPTIONS(data.getProjectInfoByHost.id, CookiesUtils.getClientId(), QuoteStore.getQuoteId, QuoteStore.getQuoteData !== null));
      }
      // eslint-disable-next-line
    }, [loading, called, data]);

    useEffect(() => {
      if (!quoteDataQuery.loading && quoteDataQuery.called && quoteDataQuery.data?.getQuoteWithClient !== undefined) {
        const quote = quoteDataQuery.data.getQuoteWithClient;

        if (quote && ProjectStore.enterStatusesApt.includes(quote.appartment.status)) {
          runInAction(() => {
            QuoteStore.setQuoteData(quote);
            ClientStore.setClientData(quoteDataQuery.data.getQuoteWithClient.client);
          });

          loadApartmentInfo(GRAPHQL.GET_APARTMENT_OPTIONS(quote.appartment.id));
        } else {
          // appartamento già personalizzato
          // o appartamento in stato non ammesso
          backToMyHome();
        }
      } else if (quoteDataQuery.error) console.error(quoteDataQuery.error);
      // eslint-disable-next-line
    }, [quoteDataQuery.loading, quoteDataQuery.called, quoteDataQuery.data]);

    useEffect(() => {
      if (!apartmentInfo.loading && apartmentInfo.called && apartmentInfo.data?.getApartmentHCV2 !== undefined) {
        const apartment = apartmentInfo.data.getApartmentHCV2;

        if (apartment) {
          runInAction(() => {
            ApartmentStore.setApartmentData(apartment);
            ProjectStore.updateFlowWithSections(apartment.sections);
            setInitializedApartment(true);
          });
        } else {
          console.error('No Apartment found');
        }
      } else if (apartmentInfo.error) {
        console.error(apartmentInfo.error);
      }
      // eslint-disable-next-line
    }, [!apartmentInfo.loading, apartmentInfo.called, apartmentInfo.data]);

    if (
      (!loading && error) ||
      (!quoteDataQuery.loading && quoteDataQuery.error) ||
      (!apartmentInfo.loading && apartmentInfo.error) ||
      (data?.getProjectInfoByHost === null && !loading && called && data)
    ) {
      return <ErrorPage hasNavigate={false} />;
    }

    if (!initializedApartment || !initializedLanguage) {
      return LOADER;
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <CurrencyProvider>
          <div id='outerDiv' data-testid='outerDiv'>
            {ProjectStore.baseurl && (
              <>
                <ServiceWorkerWrapper baseUrl={ProjectStore.baseurl} />
                <Router basename={ProjectStore.baseurl}>
                  <Suspense fallback={LOADER}>
                    <Routes>
                      <Route exact path='/404' name='Page 404' element={<ErrorPage />} />
                      <Route path='/*' name='Routes initializer' element={<RoutesInitializer />} />
                    </Routes>
                  </Suspense>
                </Router>
              </>
            )}
          </div>
        </CurrencyProvider>
      </ErrorBoundary>
    );
  }),
);

export default App;
