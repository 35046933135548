import { observer, inject } from 'mobx-react';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import IconButton from './IconButton';

const GenericModal = inject()(
  observer(
    forwardRef(({ closeTestId = null, dataTestId = null, setShowModal, showModal, children, className = '', onCancel = null, withCloseButton = true, disableClickOutside = false }, ref) => {
      const [show, setShow] = useState(showModal);
      const [completeHide, setCompleteHide] = useState(true);

      const handleShow = useCallback(() => {
        setCompleteHide(false);
        setShow(true);
      }, []);

      const preventPropagation = useCallback((e) => {
        e.stopPropagation();
      }, []);

      const handleClose = useCallback(() => {
        setShow(false);
        if (setShowModal) {
          setShowModal(false);
        }
        if (onCancel) {
          onCancel();
        }

        setCompleteHide(true);
      }, [setShowModal, onCancel]);

      useImperativeHandle(ref, () => ({
        showModal: () => {
          handleShow();
        },
        closeModal: () => {
          handleClose();
        },
      }));

      return (
        <div
          className={`hc-modal ${className} ${show ? 'showing' : 'hiding'} ${completeHide ? 'hide' : ''}`}
          data-testid={dataTestId}
          onClick={() => {
            if (!disableClickOutside) {
              handleClose();
            }
          }}
        >
          <div className='hc-modal--container' onClick={preventPropagation}>
            {withCloseButton ? <IconButton icon='close' iconSize='small-1' onClick={handleClose} dataTestId={closeTestId} /> : null}

            {children}
          </div>
        </div>
      );
    }),
  ),
);

export default GenericModal;
