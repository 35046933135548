import { gql } from '@apollo/client';

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      language
      locale
      role
      hasGoogleToken
      hasMicrosoftToken
      vendorColor
      TwoFA
`;

export const GET_USER_INFO = gql`
query getUserByJWT($project_id: ID!) {
  getUserByJWT(project_id: $project_id) {
    ${USER_DATA_TO_RETRIEVE}
  }
}
`;

export const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};

export const GET_USER_INFO_DEFAULT_OPTIONS_V2 = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  };
};

export const UPDATE_USER_LANGUAGE = gql`
mutation updateUserLanguage($id: ID!, $project_id: ID!, $language: String!, $locale: String) {
  updateUserLanguage(id: $id, project_id: $project_id, language: $language, locale: $locale) {
    ${USER_DATA_TO_RETRIEVE}
  }
}
`;

export const UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS = (id, pId, language, locale) => {
  return {
    variables: {
      id,
      project_id: pId,
      language,
      locale,
    },
  };
};
