import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { buildToolLink } from 'utils/utils';
import CookiesUtils from 'utils/cookiesUtils';

const API = (ProjectStore) => {
  const errorLink = onError(({ networkError }) => {
    if (networkError?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' || networkError?.statusCode === 401) {
      CookiesUtils.removeClientId();
      /*
        Redirect MYHOME
      */
      const MY_HOME_LINK = buildToolLink('hc', 'myhome', ProjectStore.getHost, ProjectStore.getSite('MyHome'));
      if (!window.location.url.includes(MY_HOME_LINK)) window.location.assign(MY_HOME_LINK);
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    credentials: 'include',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default API;
