import { gql } from '@apollo/client';

export const CONFIRM_CUSTOMIZATIONS_DATA_TO_RETRIEVE = `
  code
  message
`;

export const RESEND_CONFIRM_EMAIL_DATA_TO_RETRIEVE = `
  code
  message
`;

export const CONFIRM_CUSTOMIZATIONS = gql`
  mutation confirmCustomizations($project_id :ID!, $input: CustomizationsInput!) {
    confirmCustomizations(project_id: $project_id, input: $input) {
      ${CONFIRM_CUSTOMIZATIONS_DATA_TO_RETRIEVE}
      }
  }
`;

export const CONFIRM_CUSTOMIZATIONS_OPTIONS = (projectId, quoteInput, skip = false) => {
  return {
    variables: {
      project_id: projectId,
      input: quoteInput,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};

export const RESEND_CONFIRM_EMAIL = gql`
  mutation resendConfirmEmail($project_id :ID!, $input: CustomizationsInput!) {
    resendConfirmEmail(project_id: $project_id, input: $input) {
      ${RESEND_CONFIRM_EMAIL_DATA_TO_RETRIEVE}
      }
  }
`;

export const RESEND_CONFIRM_EMAIL_OPTIONS = (projectId, quoteInput, skip = false) => {
  return {
    variables: {
      project_id: projectId,
      input: quoteInput,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};
