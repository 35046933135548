import React from 'react';
import { useTranslation } from '@tecma/i18n';
import { getLabelKey } from 'utils/utils';
import AppartmentSurfaces from 'constants/AppartmentSurfaces';

function ApartmentInfo({ prjName, decimalSuperfici, apartment }) {
  const { t } = useTranslation();

  return (
    <div className='apartment-info-container'>
      <div className='apartment-info--planimetry'>
        <img
          data-testid='apartment-info-image'
          src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(prjName)}/floorplanning/img/planimetrie/${apartment.plan.name}.png`}
          alt='Appartment Planimetry'
        />
      </div>
      <div className='apartment-info--details'>
        <div className='apartment-info--title' data-testid='generic-page-apartment-name'>
          {apartment.name}
        </div>
        <div className='apartment-info--subtitle' data-testid='generic-page-apartment-typology-model'>
          {t(getLabelKey(apartment.plan.typology.name), { defaultValue: apartment.plan.typology.name })} | {t(getLabelKey(apartment.plan.model.name), { defaultValue: apartment.plan.model.name })}
        </div>
        <div className='apartment-info-details-container'>
          {apartment.superfici &&
            AppartmentSurfaces.filter((supLabel) => apartment.superfici[supLabel.replace(' ', '')]).reduce((res, supLabel) => {
              const supKey = supLabel.replace(' ', '');
              const labelKey = getLabelKey(supLabel);

              let superficie = apartment.superfici[supKey.replace(' ', '')];
              if (typeof superficie !== 'undefined' && superficie !== null && typeof superficie === 'string') {
                superficie = superficie.replace(',', '.');
              }
              superficie = superficie ? (decimalSuperfici ? parseFloat(superficie) : parseInt(Math.round(superficie), 10)) : 0;

              if (superficie !== 0)
                res.push(
                  <div id={`ico-sup${supKey}`} className='single-sup-container' key={supKey}>
                    {/* <span className={`icon large ico-sup-${supKey}`} /> */}
                    <div className='sup-title'>{t(labelKey, { defaultValue: supLabel })}</div>
                    <div className='sup-value' data-testid={`generic-page-sup-${supKey}-value`}>
                      {`${decimalSuperfici && superficie % 1 ? superficie.toFixed(2) : superficie} ${t('label.quotation.mq', { defaultValue: 'mq' })} `}
                    </div>
                  </div>,
                );

              return res;
            }, [])}
          <div id='ico-piano' className='single-sup-container' data-testid='generic-page-piano-value'>
            {/* <span className='icon large ico-sup-piano' /> */}
            <div className='sup-title'>{t('label.apartmentInfo.floor', { defaultValue: 'Piano' })}</div>
            <div className='sup-value'>{apartment.floor}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ApartmentInfo);
