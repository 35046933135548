/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { observer, inject } from 'mobx-react';

const Select = inject()(
  observer(({ items = [], selected = null, onChange = () => {}, withDot = false, className = '', dataTestId = '', iconColor = null }) => {
    const [isOpen, setOpen] = useState(false);

    const itemsMap = items.reduce((obj, item) => {
      obj[item.value] = item;
      return obj;
    }, {});

    const [selectedItem, setSelectedItem] = useState(selected);

    const iconStyle = iconColor ? { backgroundColor: iconColor } : null;

    const handleItemClick = (value) => {
      if (selectedItem !== value) setSelectedItem(value);
      onChange(value);
      setOpen(false);
    };

    return (
      <div className={`dropdown ${className}`} data-testid={dataTestId}>
        <div className='dropdown-header' onClick={() => setOpen(!isOpen)}>
          {/* {selectedItem ? (itemsMap[selectedItem].minLabel ? itemsMap[selectedItem].minLabel : itemsMap[selectedItem].label) : items.length !== 0 ? items[0].label : ''} */}
          <span data-testid={`${dataTestId}-shown`}>
            {selectedItem && itemsMap[selectedItem] ? (itemsMap[selectedItem].minLabel ? itemsMap[selectedItem].minLabel : itemsMap[selectedItem].label) : items.length !== 0 ? items[0].label : ''}
          </span>
          <i className={`icon arrow-down ${isOpen ? 'open' : ''}`} style={iconStyle} />
        </div>
        <div className={`overlay-dropdown ${isOpen && 'open'}`} onClick={() => setOpen(false)} />
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          {items.map((item, index) => (
            <div className='dropdown-item' onClick={(e) => handleItemClick(e.target.id)} id={item.value} key={`select-${index}-${item.label}`} data-testid={`${dataTestId}-option-${index}`}>
              {withDot && <span className={`dropdown-item-dot ${item.value === selectedItem && 'selected'}`}>• </span>}
              {item.label}
            </div>
          ))}
        </div>
      </div>
    );
  }),
);

export default Select;
