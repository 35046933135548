import { gql } from '@apollo/client';

const QUOTE_DATA_TO_RETRIEVE = `
  id 
  quoteNumber
  customizations {
    price
    pdfFileName
    configurationNumber
    vendor
  }
  client {
    id
    firstName
    lastName
  }
  vendor
  appartment {
    status
    name
    id
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    status
    extraInfo
    superfici
    selectedBy
    {
      id
      client {
        id
      }
    }
    plan
    {
    name
    id
    extraInfo
    model {
        id
        name
        visits
    }
    typology {
        id
        name
        visits
        rooms
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
    }
    dimension {
      name
    }
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    visits
    image
    }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
  }
  reserved
  errorMessage
`;

const ENBABLED_CUSTOM = `
    enabled
`;

export const GET_QUOTE = gql`
  query getQuoteWithClient($project_id :ID!, $id: ID!, $client: ID) {
    getQuoteWithClient(project_id: $project_id, id: $id, client: $client) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_QUOTE_OPTIONS = (projectId, clientId, quoteId, skip = false) => {
  return {
    variables: {
      project_id: projectId,
      id: quoteId,
      client: clientId,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};

export const CAN_ADD_CUSTOMIZATIONS = gql`
query canAddCustomizations($project_id: ID!, $id: ID!) {
  canAddCustomizations(project_id: $project_id, id: $id){
    ${ENBABLED_CUSTOM}
  }
}`;

export const CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id,
    },
  };
};
