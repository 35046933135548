import React, { useEffect } from 'react';
import 'SCSS/App.scss';
import App from 'App';
import AuthChecker from 'utils/AuthChecker';
import { ProjectStore } from 'stores';

function AppWrapper() {
  useEffect(() => {
    ProjectStore.clearProject();
  }, []);

  return (
    <>
      <App />
      <AuthChecker />
    </>
  );
}

export default AppWrapper;
