import { gql } from '@apollo/client';

export const FILTERED_FINISHES_DATA_TO_RETRIEVE = `
  id
  name
  code
  description
  sections
  price
  supplier
  type
  specificType
  optionType
`;

export const GET_FILTERED_FINISHES = gql`
  query getFilteredFinishes($list: [ID]!, $section: ID, $apartment: ID) {
    getFilteredFinishes(list: $list, section: $section, apartment: $apartment) {
      ${FILTERED_FINISHES_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_FILTERED_FINISHES_OPTIONS = (list, section, apartment) => {
  return {
    variables: {
      list,
      section,
      apartment,
    },
    fetchPolicy: 'network-only',
    skip: false,
  };
};
