import { observer, inject } from 'mobx-react';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Button from 'components/common/controls/Button';
import GenericModal from 'components/common/controls/GenericModal';

const ConfirmCancelModal = inject()(
  observer(
    forwardRef(
      (
        {
          dataTestId = null,
          cancelTestId = null,
          confirmTestId = null,
          closeTestId = null,
          className = '',
          title,
          children,
          confirmButtonText = 'Conferma',
          cancelButtonText = 'Cancella',
          onConfirm = null,
          onCancel = null,
          invertButtons = false,
          disableClickOutside = false,
        },
        ref,
      ) => {
        const modalRef = useRef(null);

        const handleShow = useCallback(() => {
          modalRef.current.showModal();
        }, []);

        const handleClose = useCallback(() => {
          modalRef.current.closeModal();
        }, []);

        useImperativeHandle(ref, () => ({
          showModal: () => {
            handleShow();
          },
          closeModal: () => {
            handleClose();
          },
        }));

        const handleClickConfirm = useCallback(() => {
          if (onConfirm) {
            onConfirm();
          }
          handleClose();
        }, [onConfirm, handleClose]);

        const handleClickCancel = useCallback(() => {
          if (onCancel) {
            onCancel();
          }
          handleClose();
        }, [onCancel, handleClose]);

        return (
          <GenericModal className={className} ref={modalRef} dataTestId={dataTestId} closeTestId={closeTestId} disableClickOutside={disableClickOutside}>
            <div className='hc--modal-title'>{title}</div>
            <div className='hc--modal-body'>
              {children}

              <div className='hc--modal-action-button-container'>
                {!invertButtons ? (
                  <>
                    <Button dataTestId={cancelTestId} onClick={handleClickCancel} className='modal-cancel-button primaryBorderColor'>
                      {cancelButtonText}
                    </Button>
                    <Button dataTestId={confirmTestId} inverted onClick={handleClickConfirm} className='modal-confirm-button'>
                      {confirmButtonText}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button dataTestId={confirmTestId} inverted onClick={handleClickConfirm} className='modal-confirm-button'>
                      {confirmButtonText}
                    </Button>
                    <Button dataTestId={cancelTestId} onClick={handleClickCancel} className='modal-cancel-button primaryBorderColor'>
                      {cancelButtonText}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </GenericModal>
        );
      },
    ),
  ),
);

export default ConfirmCancelModal;
