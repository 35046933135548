import { makeAutoObservable } from 'mobx';
import { isEqual, omit } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';

class UserStore {
  loggedUser = null;
  checkingJwt = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.loggedUser = null;
    this.checkingJwt = false;
  }

  setLoggedUser(userData) {
    if (!this.loggedUser || !isEqual(omit(this.loggedUser, ['language', 'TwoFA']), omit(userData, ['language', 'TwoFA']))) {
      this.loggedUser = userData;
      if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
        datadogRum.setUser({
          id: userData.id,
          name: userData.firstName,
          surname: userData.lastName,
          email: userData.email,
          role: userData.role,
          TwoFA: userData.TwoFA,
        });
      }
    }
  }

  setCheckingJwt(checkingJwt) {
    this.checkingJwt = checkingJwt;
  }

  get getLoggedUser() {
    return this.loggedUser;
  }

  get getCheckingJwt() {
    return this.checkingJwt;
  }

  logOut() {
    this.loggedUser = null;
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.clearUser();
    }
  }
}

const userStore = new UserStore();
export default userStore;
