/* eslint-disable no-restricted-syntax */
import { makeAutoObservable } from 'mobx';

class LanguageStore {
  languages = ['en-GB', 'it-IT'];
  languageChangeHandler = null;
  languagesInitialized = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUserLanguage(userLanguage, update = true) {
    const availableLanguages = this.languages;

    if (availableLanguages.includes(userLanguage) && this.languageChangeHandler) this.languageChangeHandler(userLanguage, update);
  }

  setLanguageChangeHandler(handler) {
    this.languageChangeHandler = handler;
  }

  closestLanguage(lang) {
    const availableLanguages = this.languages;

    for (let i = 0; i < availableLanguages.length; i += 1) if (availableLanguages[i].startsWith(lang)) return availableLanguages[i];

    return null;
  }

  reset() {
    this.languages = ['en-GB', 'it-IT'];
  }

  setDBLanguages(languages) {
    this.languages = languages;
    this.languagesInitialized = true;
  }

  get getLanguages() {
    return this.languages;
  }

  get getLangObjects() {
    return this.languages;
  }
}

const languageStore = new LanguageStore();
export default languageStore;
