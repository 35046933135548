import { gql } from '@apollo/client';
import { MOOD_DATA_TO_RETRIEVE } from 'graphql/modules/Mood';
import { SECTION_DATA_TO_RETRIEVE } from './Section';

export const SPECIFICATION_DATA_TO_RETRIEVE = `
  id
  name
  code
  price
  shortDescription
  moods {
    ${MOOD_DATA_TO_RETRIEVE}
  }
  sections {
    ${SECTION_DATA_TO_RETRIEVE}
  }
  finishes
  optionType
`;

export const GET_ALL_SPECIFICATIONS = gql`
  query getAllSpecifications($project_id :ID!) {
    getAllSpecifications(project_id: $project_id) {
      ${SPECIFICATION_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_ALL_SPECIFICATIONS_OPTIONS = (projectId, skip = false) => {
  return {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};
