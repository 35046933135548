import { observer, inject } from 'mobx-react';
import Button from 'components/common/controls/Button';
import SupportModal from 'components/common/modals/SupportModal';
import { useCallback, useRef } from 'react';
import ConfirmCancelModal from 'components/common/modals/ConfirmCancelModal';
import { buildToolLink } from 'utils/utils';
import { useTranslation, Trans } from '@tecma/i18n';
import Select from 'components/common/controls/Select';

const Header = inject(
  'ProjectStore',
  'LanguageStore',
)(
  observer(({ ProjectStore, LanguageStore }) => {
    const { t, i18n } = useTranslation();
    const supportModalRef = useRef(null);
    const quitModalRef = useRef(null);

    const languages = LanguageStore.getLangObjects.map((isoId) => {
      return {
        value: isoId,
        label: t(`lang.${isoId}`, { defaultValue: isoId }),
        minLabel: t('lang.current'),
      };
    });

    const handleClickSupport = useCallback(() => {
      if (supportModalRef) {
        supportModalRef.current.showModal();
      }
    }, []);

    const handleClickQuit = useCallback(() => {
      if (quitModalRef) {
        quitModalRef.current.showModal();
      }
    }, []);

    const backToMyHome = useCallback(() => {
      const MY_HOME_LINK = buildToolLink('hc', 'myhome', ProjectStore.getHost, ProjectStore.getSite('MyHome'));
      window.location.assign(MY_HOME_LINK);
    }, [ProjectStore]);

    return (
      <>
        <SupportModal ref={supportModalRef} />
        <ConfirmCancelModal
          dataTestId='header-close-configurator-modal'
          cancelTestId='header-close-configurator-modal-cancel'
          confirmTestId='header-close-configurator-modal-confirm'
          closeTestId='header-close-configurator-modal-close'
          onCancel={backToMyHome}
          className='quit-modal'
          ref={quitModalRef}
          title={t('label.header.beforeYouGoAway', { defaultValue: 'Prima che tu vada via' })}
          confirmButtonText={t('label.header.backToConfigure', { defaultValue: 'Torna a configurare' })}
          cancelButtonText={t('label.header.understandQuit', { defaultValue: 'Ho capito, esci' })}
        >
          <Trans
            i18nKey='label.header.loseInformation'
            defaults='<b>Non</b> hai inviato la tua configurazione. Se esci, le selezioni fatte verranno azzerate e, al nuovo accesso, dovrai ricominciare da capo.'
            components={{ b: <strong /> }}
          />
        </ConfirmCancelModal>
        <div className='hc--header' data-testid='header'>
          <div className='hc--header-left'>
            <img data-testid='header-logo-iniziativa' src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(ProjectStore.getName)}/global/img/primary-logo.svg`} alt='Iniziativa' />
          </div>
          <div className='hc--header-right'>
            <Select
              items={languages}
              dataTestId='header-change-language'
              selected={i18n.language}
              className='hc--header-controls hc--button header-right-element text'
              onChange={(val) => {
                LanguageStore.setUserLanguage(val);
              }}
            />

            <Button className='hc--header-controls header-right-element text' outline={false} onClick={handleClickSupport} dataTestId='header-support' icon='question'>
              {t('label.header.support', { defaultValue: 'Assistenza' })}
            </Button>
            <Button className='hc--header-controls header-right-last text' onClick={handleClickQuit} dataTestId='header-close-configurator' icon='sign_out'>
              {t('label.header.closeConfigurator', { defaultValue: 'Esci dal configuratore' })}
            </Button>
          </div>
        </div>
      </>
    );
  }),
);

export default Header;
