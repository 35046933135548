import { observer, inject } from 'mobx-react';
import { useCallback } from 'react';
import Loader from 'components/common/Loader';

const Button = inject()(
  observer(({ children, onClick = null, inverted = false, icon = null, dataTestId = null, className = '', loading = false, disabled = false, style = null, iconStyle = null, iconRight = false }) => {
    const handleClick = useCallback(() => {
      if (loading) {
        return;
      }
      if (onClick && !disabled) {
        onClick();
      }
    }, [onClick, loading, disabled]);

    return (
      <div
        data-testid={dataTestId}
        onClick={handleClick}
        className={`hc--button ${disabled ? 'disabled' : ''} ${loading ? 'loading' : ''} ${className} ${inverted ? 'inverted primaryBackgroundAndBorderColor' : ''}`}
        style={style}
      >
        {loading && <Loader whiteLoader={inverted} className='button-loader' />}
        <span className={`button-content ${icon ? 'flex-button' : ''}`}>
          {icon && !iconRight && <span className={`icon ${icon} nowrap`} style={iconStyle} />}
          <span className='nowrap'>{children}</span>
          {icon && iconRight && <span className={`icon ${icon} nowrap`} style={iconStyle} />}
        </span>
      </div>
    );
  }),
);

export default Button;
