export const MOOD = 'moods';
export const SPECIFICATION = 'specifications';
export const FINISH = 'finishes';
export const OPTIONALS = 'optionals';
export const HOMEPAGE = 'homepage';

export default {
  MOOD,
  SPECIFICATION,
  FINISH,
  OPTIONALS,
  HOMEPAGE,
};
