import { observer, inject } from 'mobx-react';
import { useCallback } from 'react';

const IconButton = inject()(
  observer(({ className = '', dataTestId = null, onClick = null, iconSize = 'medium', icon = 'close', disabled = false, style = null }) => {
    const handleClick = useCallback(() => {
      if (disabled) {
        return;
      }
      if (onClick) {
        onClick();
      }
    }, [onClick, disabled]);

    return (
      <span data-testid={dataTestId} style={style} onClick={handleClick} className={`hc--icon-button icon ${iconSize} ${icon} ${className} ${disabled ? 'disabled' : ''}`}>
        {' '}
      </span>
    );
  }),
);

export default IconButton;
