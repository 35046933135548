import { toJS } from 'mobx';
import { TYPE } from 'constants/Type';
import { OPTION_TYPES } from 'constants/optionTypes';

/**
 *
 * @param {*} name the name of the parameter
 */
export function getQSParameterValue(originalName, url = window.location.href) {
  const name = originalName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getLabelKey(value) {
  return value.replace(/\s/g, '').replace(/\//g, '_').toLowerCase();
}

export function getLabelKeyComposite(prefix, value, suffix) {
  return `${prefix}_${value}_${suffix}`;
}

export function getBucketProjectBaseUrl(projectName) {
  return `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(projectName)}/HomeConfigurator`;
}

export function getComposedImageUrl(selections) {
  return selections
    .map((value) => getLabelKey(value))
    .map((value) => value.replace(/\s/g, ''))
    .join('_');
}

export function buildNavigation(activeFilters, navigation) {
  let newNav = navigation;
  Object.keys(activeFilters).forEach((key) => {
    newNav = newNav.replace(`:${key}`, activeFilters[key]);
  });
  const endsWithSlash = newNav.length !== 0 && newNav.charAt(newNav.length - 1) === '/';
  return `${newNav}${!endsWithSlash ? '/' : ''}`;
}

export function buildToolLink(oldTool, newTool, host = null, toolUrl = null) {
  try {
    const hostKey = host || localStorage.myhome_dev_hostname;

    let finalHostName = '';
    const hostname = window.location.hostname.replace('www.', '');

    if (hostname.indexOf('localhost') >= 0) {
      // devo puntare a biz-tecma-dev1 con relativo hostname
      finalHostName = `https://${newTool}-biz-tecma-dev1.tecmasolutions.com/?hostname=${hostKey}&`;
    } else if (hostname.indexOf('biz-tecma') >= 0 || hostname.indexOf('-demo.tecmasolutions.com') >= 0) {
      const newHostName = hostname.replace(oldTool, newTool);
      // devo puntare a biz-tecma-dev1 con relativo hostname
      finalHostName = `https://${newHostName}/?hostname=${hostKey}&`;
    } else if (toolUrl) {
      finalHostName = toolUrl;
    } else {
      finalHostName = `https://${hostKey.replace('www', newTool)}/?`;
    }

    return finalHostName;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export const getNavigationSelectionObject = (type, selectionName, object) => {
  let objectToReturn = null;
  switch (type) {
    case TYPE.MOOD_UPPERCASE_FIRST:
    case TYPE.SPECIFICATIONONLY_UPPERCASE_FIRST:
      objectToReturn = {
        name: selectionName,
        type,
        value: object.price,
        optionType: object.optionType,
        note: object.note,
        object,
      };
      break;
    case TYPE.FINISH_UPPERCASE_FIRST:
      objectToReturn = {
        name: selectionName,
        type,
        value: object.selectedFinish.price,
        optionType: object.selectedFinish.optionType,
        note: object.note,
        object,
      };
      break;
    case TYPE.MULTIFINISH_UPPERCASE_FIRST: {
      const finishesPrice = object.selectedFinishes.reduce((prev, finish) => prev + finish.price, 0);
      const allFinishesSkip = object.selectedFinishes.reduce((prev, finish) => prev && finish.optionType === OPTION_TYPES.SKIP, true);
      const finishesOptionType = allFinishesSkip ? OPTION_TYPES.SKIP : finishesPrice === 0 ? OPTION_TYPES.INCLUDED : OPTION_TYPES.PRICE;
      objectToReturn = {
        name: selectionName,
        type,
        value: finishesPrice,
        optionType: finishesOptionType,
        note: object.note,
        object,
      };
      break;
    }
    case TYPE.OPTIONALS_UPPERCASE_FIRST: {
      const optionalsPrice = object.selected.reduce((prev, optional) => prev + optional.price, 0);
      const allOptionalsSkip = object.selected.reduce((prev, optional) => prev && optional.optionType === OPTION_TYPES.SKIP, true);
      const finishesOptionType = allOptionalsSkip ? OPTION_TYPES.SKIP : optionalsPrice === 0 ? OPTION_TYPES.INCLUDED : OPTION_TYPES.PRICE;

      objectToReturn = {
        name: selectionName,
        type,
        value: object.selected.reduce((prev, optional) => prev + optional.price, 0),
        optionType: finishesOptionType,
        note: object.note,
        object,
      };
      break;
    }
    default:
      console.error('Component not found!!!!');
      break;
  }

  return objectToReturn;
};

export const getSpecificationFromNavigation = (steps) => {
  const specificationSteps = steps.filter((step) => step.type === TYPE.SPECIFICATIONONLY_UPPERCASE_FIRST);
  if (specificationSteps.length > 1) console.warn('Più di uno step allestimenti, considerato solo il primo');
  else if (specificationSteps.length === 0) {
    console.error('Nessuno step allestimento trovato.');
    return null;
  }
  return specificationSteps[0].object;
};

export const buildQuoteHCInputObject = (QuoteStore, selections = [], flow = []) => {
  const customizations = [];
  selections.forEach((selection, selectionIndex) => {
    const flowSelectionIndex = flow?.[selectionIndex]?.filters?.[0];
    switch (selection.type) {
      case TYPE.SPECIFICATIONONLY_UPPERCASE_FIRST: {
        customizations.push({
          name: selection.name,
          code: selection.object.code,
          price: selection.value,
          optionType: selection.optionType,
          note: selection.object.note,
          type: selection.type.toUpperCase(),
          subselections: [],
          originalObject: toJS(selection.object),
        });
        break;
      }
      case TYPE.FINISH_UPPERCASE_FIRST: {
        customizations.push({
          name: selection.name,
          code: selection.object.selectedFinish.code,
          note: selection.object.note,
          section: flowSelectionIndex?.value,
          sectionCode: flowSelectionIndex?.code,
          price: selection.value,
          optionType: selection.optionType,
          type: selection.type.toUpperCase(),
          subselections: [],
          finishType: selection.object.selectedFinish.type,
          originalObject: toJS(selection.object.selectedFinish),
        });
        break;
      }
      case TYPE.MULTIFINISH_UPPERCASE_FIRST: {
        customizations.push({
          name: TYPE.MULTIFINISH_UPPERCASE_FIRST,
          section: flowSelectionIndex?.value,
          sectionCode: flowSelectionIndex?.code,
          price: selection.value,
          optionType: selection.optionType,
          type: selection.type.toUpperCase(),
          note: selection.object?.note,
          subselections: selection.object.selectedFinishes.map((f) => ({
            name: f.name,
            section: flowSelectionIndex?.value,
            sectionCode: flowSelectionIndex?.code,
            price: f.price,
            type: f.type.toUpperCase(),
            subselections: [],
            code: f.code,
            originalObject: toJS(f),
          })),
        });
        break;
      }
      case TYPE.OPTIONALS_UPPERCASE_FIRST: {
        const subselections = selection.object.selected.map((opt) => {
          return {
            name: opt.name,
            code: opt.code,
            originalObject: toJS(opt),
            price: opt.price,
            optionType: opt.optionType,
            type: selection.type.toUpperCase(),
            subselections: [],
          };
        });

        customizations.push({
          name: TYPE.OPTIONALS_UPPERCASE_FIRST,
          note: selection.object.note,
          type: selection.type.toUpperCase(),
          price: subselections.reduce((prev, curr) => prev + curr.price, 0),
          optionType: selection.optionType,
          subselections,
        });
        break;
      }
      default:
        customizations.push({
          name: selection.name,
          price: selection.value,
          optionType: selection.optionType,
          type: selection.type.toUpperCase(),
          note: selection.object.note,
          subselections: [],
          code: selection.object.code,
          originalObject: toJS(selection.object),
        });
        break;
    }
  });

  const quoteData = toJS(QuoteStore.getQuoteData);

  const objectToReturn = {
    quote: quoteData,
    client: QuoteStore.getClientId,
    vendor: QuoteStore.getVendorId,
    customizations,
  };

  return objectToReturn;
};

export const downloadPdf = (file) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = `${file.name}.pdf`;
  a.click();
};

export const checkFileExist = (urlToFile) => {
  const xhr = new XMLHttpRequest();
  xhr.open('HEAD', urlToFile, false);

  xhr.send();

  return xhr.status !== 404;
};

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const firstValidOption = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i += 1) if (arr1[i] && arr2.includes(arr1[i])) return arr1[i];

  return null;
};

export const getPriceOrLabel = ({ price, formatter, translation, template = false, optionType }) => {
  if (price === null) {
    console.warn('Current price is null');
    return null;
  }
  if (optionType === OPTION_TYPES.INCLUDED) {
    return translation('app.included', { defaultValue: 'INCLUSO' });
  }
  if (optionType === OPTION_TYPES.PRICE) {
    return template ? formatter(price) : `+ ${formatter(price)}`;
  }
  if (optionType === OPTION_TYPES.SKIP) {
    return null;
  }
  console.error('optionType not recognized!', optionType);
  const err = new Error();
  console.error(err.stack);

  return null;
};

export default {
  getQSParameterValue,
  getLabelKey,
  getLabelKeyComposite,
  buildNavigation,
  buildToolLink,
  getNavigationSelectionObject,
  buildQuoteHCInputObject,
  downloadPdf,
  checkFileExist,
  blobToBase64,
  firstValidOption,
  getPriceOrLabel,
};
