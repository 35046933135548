import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class ApartmentStore {
  apartment = null;
  sections = [];
  moods = [];
  specifications = [];
  optionals = [];
  finishesPrices = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ApartmentStore_HomeConfigurator',
      properties: ['apartment', 'sections', 'moods', 'specifications', 'optionals, finishesPrices'],
      storage: window.localStorage,
    });
  }

  reset() {
    this.apartment = null;
    this.sections = [];
    this.moods = [];
    this.specifications = [];
    this.optionals = [];
    this.finishesPrices = [];
  }

  setApartmentData(apartmentData) {
    this.apartment = apartmentData.aptId;
    this.sections = apartmentData.sections;
    this.moods = apartmentData.moods;
    this.specifications = apartmentData.specifications;
    this.optionals = apartmentData.optionals;
  }

  getApartment() {
    return this.apartment;
  }

  getSections() {
    return this.sections;
  }

  getMoods() {
    return this.moods;
  }

  getSpecifications() {
    return this.specifications;
  }

  getOptionals() {
    return this.optionals;
  }

  getFinishesPrices() {
    return this.finishesPrices;
  }
}

const apartmentStore = new ApartmentStore();
export default apartmentStore;
