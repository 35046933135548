import { GET_PROJECT_INFO, GET_PROJECT_INFO_DEFAULT_OPTIONS } from 'graphql/modules/Project';
import { GET_QUOTE, GET_QUOTE_OPTIONS, CAN_ADD_CUSTOMIZATIONS, CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS } from 'graphql/modules/Quote';
import { GET_APARTMENT, GET_APARTMENT_OPTIONS } from 'graphql/modules/Apartment';
import { GET_ALL_MOODS, GET_ALL_MOODS_OPTIONS } from 'graphql/modules/Mood';
import { GET_ALL_SPECIFICATIONS, GET_ALL_SPECIFICATIONS_OPTIONS } from 'graphql/modules/Specification';
import { GET_FILTERED_FINISHES, GET_FILTERED_FINISHES_OPTIONS } from 'graphql/modules/Finish';
import { GET_ALL_OPTIONALS, GET_ALL_OPTIONALS_OPTIONS } from 'graphql/modules/Optional';
import { CONFIRM_CUSTOMIZATIONS, CONFIRM_CUSTOMIZATIONS_OPTIONS, RESEND_CONFIRM_EMAIL, RESEND_CONFIRM_EMAIL_OPTIONS } from 'graphql/modules/HC';
import { GET_USER_INFO, GET_USER_INFO_DEFAULT_OPTIONS, GET_USER_INFO_DEFAULT_OPTIONS_V2, UPDATE_USER_LANGUAGE, UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS } from 'graphql/modules/User';

import { LOGOUT } from 'graphql/modules/Auth';

const GRAPHQL = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,

  GET_QUOTE,
  GET_QUOTE_OPTIONS,
  CAN_ADD_CUSTOMIZATIONS,
  CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS,

  GET_APARTMENT,
  GET_APARTMENT_OPTIONS,

  GET_ALL_MOODS,
  GET_ALL_MOODS_OPTIONS,

  GET_ALL_SPECIFICATIONS,
  GET_ALL_SPECIFICATIONS_OPTIONS,

  GET_FILTERED_FINISHES,
  GET_FILTERED_FINISHES_OPTIONS,

  GET_ALL_OPTIONALS,
  GET_ALL_OPTIONALS_OPTIONS,

  CONFIRM_CUSTOMIZATIONS,
  CONFIRM_CUSTOMIZATIONS_OPTIONS,
  RESEND_CONFIRM_EMAIL,
  RESEND_CONFIRM_EMAIL_OPTIONS,

  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS_V2,

  UPDATE_USER_LANGUAGE,
  UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS,

  LOGOUT,
};

export default GRAPHQL;
