import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
    id
    name
    logo
    vendorLogo
    enabledTools
    assetLogin
    hostKey
    assetKey
    feVendorKey
    pdf
    assetsUrl
    displayName
    proposalTemplate
    payoff
    manifestConfig
    homeConfiguratorConfig
    contactPhone
    contactEmail
    legalNotesLinks {
      cookies
      use
      privacy
    }
    baseurl
    locale
    currency
`;

export const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};
