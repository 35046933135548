import React, { lazy } from 'react';
import { observer, inject } from 'mobx-react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Header from 'components/common/Header';
import Icons from 'components/common/Icons';
import Footer from 'components/common/Footer';
import CustomStyleGenerator from 'components/common/CustomStyleGenerator';
import { runInAction } from 'mobx';
import MobileHandler from 'components/common/MobileHandler';
import HomePage from './pages/HomePage';
import ErrorPage from './common/ErrorPage';

const Mood = lazy(() => import('components/pages/Mood'));
const SpecificationOnly = lazy(() => import('components/pages/SpecificationOnly'));
const Finish = lazy(() => import('components/pages/Finish'));
const Optionals = lazy(() => import('components/pages/Optionals'));
const Summary = lazy(() => import('components/pages/Summary'));

const componentsMap = {
  Mood,
  SpecificationOnly,
  Finish,
  Optionals,
  Summary,
};

const PreRenderComponent = inject('NavigationStore')(
  observer(({ children, NavigationStore, pageConfig }) => {
    // route pre-render component
    const params = useParams();
    // tolgo selezioni di pagine dopo questa
    if (NavigationStore.getNavigationSelection.length > pageConfig.index - 1) {
      runInAction(() => {
        NavigationStore.setNavigationSelection(
          NavigationStore.getNavigationSelection.filter((el, index) => {
            return index < pageConfig.index - 1;
          }),
        );
      });
    }
    runInAction(() => {
      // setto currentPage
      NavigationStore.setCurrentPage(pageConfig.index);

      // set active filters
      NavigationStore.setActiveFilters(params);
    });

    return children;
  }),
);

const RoutesInitializer = inject('ProjectStore')(
  observer(({ ProjectStore }) => {
    const flow = ProjectStore.getFlow;

    const routes = flow
      .slice()
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .map((page) => {
        const reactPage = { ...page };
        reactPage.reactComponent = componentsMap[page.component];
        return (
          <Route
            path={reactPage.navigation}
            exact
            key={reactPage.index}
            element={
              <PreRenderComponent pageConfig={page} key={reactPage.index}>
                <Header />
                <reactPage.reactComponent component={reactPage} />
                <Footer />
              </PreRenderComponent>
            }
          />
        );
      });

    return ProjectStore.hasError ? (
      <ErrorPage />
    ) : (
      <div className='home-configurator-container'>
        <Icons />
        <CustomStyleGenerator />
        <MobileHandler />
        <Routes>
          <Route
            exact
            path='/'
            name='Home'
            element={
              <PreRenderComponent pageConfig={{ index: 999 }} key={999}>
                <Header />
                <HomePage />
                <Footer />
              </PreRenderComponent>
            }
          />
          {routes}
          <Route path='*' element={<Navigate to='/404' />} />
        </Routes>
      </div>
    );
  }),
);

export default RoutesInitializer;
