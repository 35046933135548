import { observer, inject } from 'mobx-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Link = inject()(
  observer(({ dataTestId = null, children, underlined = false, href = null, useHistory = false }) => {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
      if (href) {
        if (useHistory) {
          navigate(href);
        } else {
          window.location.assign(href);
        }
      }
    }, [navigate, href, useHistory]);

    return (
      <div data-testid={dataTestId} data-href={href} onClick={handleClick} className={`hc--link ${underlined ? 'underlined' : ''}`}>
        {children}
      </div>
    );
  }),
);

export default Link;
