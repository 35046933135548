import Cookies from 'js-cookie';
import psl from 'psl';

const clientKey = 'clientId';

let domain = 'localhost';
if (window.location.hostname !== 'localhost') {
  const pslUrl = psl.parse(window.location.hostname);
  domain = pslUrl.domain;
}

const cookieOptionsSet = {
  expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
  domain,
};

const cookieOptionsRemove = {
  domain,
};

function set(key, value) {
  Cookies.set(key, value, cookieOptionsSet);
}

function get(key) {
  return Cookies.get(key);
}

function remove(key) {
  return Cookies.remove(key, cookieOptionsRemove);
}

function getClientId() {
  return Cookies.get(clientKey);
}

function setClientId(clientId) {
  Cookies.set(clientKey, clientId, cookieOptionsSet);
}

function removeClientId() {
  Cookies.remove(clientKey, cookieOptionsRemove);
}

function vendorLogout() {
  removeClientId();
}

const CookiesUtils = {
  set,
  get,
  remove,
  getClientId,
  setClientId,
  removeClientId,
  vendorLogout,
};

export default CookiesUtils;
