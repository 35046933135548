import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import { ApolloProvider } from '@apollo/client';
import logger from 'logger/logger';
import { WinstonProvider } from 'winston-react/dist/context';
import API from 'graphql/GraphQLClient';
import reportWebVitals from 'reportWebVitals';
import { ProjectStore, LanguageStore, NavigationStore, QuoteStore, ApartmentStore, UserStore, ClientStore } from 'stores';
import { Provider } from 'mobx-react';
import AppWrapper from 'AppWrapper';
import { initI18n } from '@tecma/i18n';
import translation from 'i18n/en-GB/translation.json';

const stores = { ProjectStore, LanguageStore, NavigationStore, QuoteStore, ApartmentStore, UserStore, ClientStore };

initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: 'HomeConfigurator',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
}).then(() => {
  ReactDOM.render(
    <ApolloProvider client={API(ProjectStore)}>
      <StrictMode>
        <ApolloProvider>
          <WinstonProvider logger={logger}>
            <Provider {...stores}>
              <AppWrapper />
            </Provider>
          </WinstonProvider>
        </ApolloProvider>
      </StrictMode>
    </ApolloProvider>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
