import { observer, inject } from 'mobx-react';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from '@tecma/i18n';
import { getBucketProjectBaseUrl } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/controls/Button';
import Scrollbars from 'react-custom-scrollbars';
import ApartmentInfo from 'components/common/ApartmentInfo';
import { HOMEPAGE } from 'constants/Folders';
import { useQuery } from '@apollo/client';
import ErrorModal from 'components/common/modals/ErrorModal';
import GRAPHQL from 'graphql/GraphQLEndpoints';

const HomePage = inject(
  'ProjectStore',
  'QuoteStore',
)(
  observer(({ ProjectStore, QuoteStore }) => {
    const { t } = useTranslation();
    const errorMaxNumConfRef = useRef(null);
    const [isEnabled, setIsEnabled] = useState(true);
    const enabledQuery = useQuery(GRAPHQL.CAN_ADD_CUSTOMIZATIONS, GRAPHQL.CAN_ADD_CUSTOMIZATIONS_DEFAULT_OPTIONS(ProjectStore.getProjectId, QuoteStore.getQuoteId));

    useEffect(() => {
      if (enabledQuery.called && !enabledQuery.loading) setIsEnabled(enabledQuery?.data?.canAddCustomizations?.enabled);
    }, [enabledQuery]);

    // init
    const navigate = useNavigate();
    const [apartment] = useState(QuoteStore.getAppartmentInfo);

    const handleClick = useCallback(() => {
      if (isEnabled) {
        navigate('/configuration/');
      } else {
        errorMaxNumConfRef.current.showModal(
          t('label.summary.notEnabledConfig.error.header', { defaultValue: 'Impossibile Salvare la configurazione' }),
          t('label.summary.notEnabledConfig.error.description', { defaultValue: 'Hai raggiunto il numero massimo di configurazioni disponibili' }),
        );
      }
    }, [isEnabled, navigate, t]);

    return (
      <div className='hc--single-page hc--homepage' data-testid='hc-homepage'>
        <div className='hc--generic-page-wrapper' data-testid='generic-page-wrapper'>
          <div className='hc--generic-page-container'>
            <ErrorModal ref={errorMaxNumConfRef} />
            <div className='hc--generic-page-right-sidebar'>
              <Scrollbars>
                <div className='homepage-apartment-title'>
                  <span>{t('label.layout.yourAppartment', { defaultValue: 'Il tuo appartamento' })}</span>
                </div>
                <ApartmentInfo prjName={ProjectStore.getName} decimalSuperfici={ProjectStore.decimalSuperfici} apartment={apartment} />
              </Scrollbars>
            </div>

            <div className='hc--generic-page-center'>
              <div className='hc--generic-page-center-content'>
                <div className='hc--generic-page-image-container'>
                  <div
                    key='homepage-central-image'
                    data-testid='hc-homepage-central-img'
                    className='hc--homepage-central-image'
                    style={{
                      backgroundImage: `url('${getBucketProjectBaseUrl(ProjectStore.getName)}/${HOMEPAGE}/home.jpg')`,
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className='hc--homepage-img-content'>
                      <div className='hc--homepage-title' data-testid='hc--homepage-title'>
                        {t('label.homepage.title', { defaultValue: 'Benvenuto nel configuratore della tua nuova casa' })}
                      </div>
                      <div className='hc--homepage-subtitle' data-testid='hc--homepage-subtitle'>
                        {t('label.homepage.subtitle', { defaultValue: 'Inizia subito a personalizzare' })}
                      </div>
                      <div className='hc--homepage-start-button'>
                        <Button dataTestId='hc--homepage-start-button' inverted onClick={handleClick}>
                          {t('label.homepage.confirmAndProceed', { defaultValue: 'Inizia la configurazione' })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default HomePage;
