import { observer, inject } from 'mobx-react';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useTranslation } from '@tecma/i18n';
import Button from 'components/common/controls/Button';
import GenericModal from 'components/common/controls/GenericModal';
import Link from '../controls/Link';

const SupportModal = inject('ProjectStore')(
  observer(
    forwardRef(({ ProjectStore }, ref) => {
      const { t } = useTranslation();

      const modalRef = useRef(null);

      const handleShow = useCallback(() => {
        modalRef.current.showModal();
      }, []);

      const handleClose = useCallback(() => {
        modalRef.current.closeModal();
      }, []);

      useImperativeHandle(ref, () => ({
        showModal: () => {
          handleShow();
        },
        closeModal: () => {
          handleClose();
        },
      }));

      return (
        <GenericModal dataTestId='support-modal' closeTestId='support-modal-close' className='support-modal' ref={modalRef}>
          <div className='hc--modal-title'>{t('label.supportModal.title', { defaultValue: "Hai bisogno d'aiuto?" })}</div>
          <div className='hc--modal-body'>
            {t('label.supportModal.contactUsWith', { defaultValue: 'Puoi contattarci con i seguenti metodi' })}
            <div className='contact-options'>
              <div className='single-contact-option'>
                <span className='icon medium email primaryBackgroundColor' />
                <span className='vertical-divider' />
                <Link href={`mailto:${ProjectStore.getContactEmail}`} className='single-contact-option--name' dataTestId='support-modal-email'>
                  {ProjectStore.getContactEmail}
                </Link>
              </div>
              <div className='single-contact-option'>
                <span className='icon medium phone primaryBackgroundColor' />
                <span className='vertical-divider' />
                <Link href={`tel:${ProjectStore.getContactPhone}`} className='single-contact-option--name' dataTestId='support-modal-phone'>
                  {ProjectStore.getContactPhone}
                </Link>
              </div>
            </div>

            <Button inverted onClick={handleClose}>
              {t('app.chiudi', { defaultValue: 'Chiudi' })}
            </Button>
          </div>
        </GenericModal>
      );
    }),
  ),
);

export default SupportModal;
