import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class QuoteStore {
  quoteId = null;
  quoteData = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'QuoteStore_HomeConfigurator', properties: ['quoteId', 'quoteData'], storage: window.localStorage });
  }

  reset() {
    this.quoteId = null;
    this.quoteData = null;
  }

  setQuoteId(quoteId) {
    this.quoteId = quoteId;
  }

  setQuoteData(quoteData) {
    this.quoteData = quoteData;
  }

  get getQuoteId() {
    return this.quoteId;
  }

  get getQuoteData() {
    return this.quoteData;
  }

  get getAppartmentInfo() {
    if (this.quoteData) {
      return this.quoteData.appartment;
    }

    return null;
  }

  get getAppartmentId() {
    if (this.quoteData) {
      return this.quoteData.appartment.id;
    }
    return null;
  }

  get getClientId() {
    if (this.quoteData) {
      return this.quoteData.vendor;
    }
    return null;
  }

  get getVendorId() {
    if (this.quoteData) {
      return this.quoteData.vendor;
    }
    return null;
  }
}

const quoteStore = new QuoteStore();
export default quoteStore;
