import { gql } from '@apollo/client';

export const MOOD_DATA_TO_RETRIEVE = `
  id
  name
  code
  price
  shortDescription
  optionType
`;

export const GET_ALL_MOODS = gql`
  query getAllMoods($project_id :ID!) {
    getAllMoods(project_id: $project_id) {
      ${MOOD_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_ALL_MOODS_OPTIONS = (projectId, skip = false) => {
  return {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};
