import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class NavigationStore {
  currentPage = 0;
  activeFilters = {};
  navigationSelection = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'NavigationStore_HomeConfigurator', properties: ['currentPage', 'activeFilters', 'navigationSelection'], storage: window.localStorage });
  }

  reset() {
    this.currentPage = 0;
    this.activeFilters = {};
    this.navigationSelection = [];
  }

  setCurrentPage(currentPage) {
    this.currentPage = currentPage;
  }

  setActiveFilters(params) {
    Object.keys(params)
      .filter((key) => key !== '*')
      .forEach((key) => {
        this.activeFilters[key] = params[key];
      });
  }

  setNavigationSelection(selection) {
    this.navigationSelection = selection;
  }

  get getCurrentPage() {
    return this.currentPage;
  }

  get getActiveFilters() {
    return this.activeFilters;
  }

  get getNavigationSelection() {
    return this.navigationSelection;
  }

  get getInitialChoices() {
    let mood = null;
    let specification = null;

    if (this.navigationSelection.length > 0 && this.navigationSelection[0].type === 'Mood') {
      mood = this.navigationSelection[0].object;

      if (this.navigationSelection.length > 1 && this.navigationSelection[1].type === 'SpecificationOnly') specification = this.navigationSelection[1].object;
    }
    if (this.navigationSelection.length > 0 && this.navigationSelection[0].type === 'SpecificationOnly') specification = this.navigationSelection[0].object;

    return [mood, specification];
  }

  get currentTotal() {
    return this.navigationSelection.reduce((prev, curr) => prev + curr.value, 0);
  }
}

const navigationStore = new NavigationStore();
export default navigationStore;
