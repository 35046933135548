import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from '@tecma/i18n';

const MobileHandler = observer(() => {
  const { t } = useTranslation();
  return (
    <div id='mobile-content'>
      <h1 className='mobile-message'>{t('app.noMobile', { defaultValue: "Il tuo schermo è troppo piccolo. Per accedere all'applicativo utilizza un PC." })}</h1>
    </div>
  );
});

export default MobileHandler;
