import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

const GenericSnackbar = observer(
  forwardRef((props, ref) => {
    const { className = '', children, show = false, dataTestId = null, extraStyle = undefined } = props;
    const [showSnackbar, setShowSnackbar] = useState(show);
    const [textToDisplay, setTextToDisplay] = useState(null);

    useEffect(() => {
      setShowSnackbar(show);
    }, [show]);

    // per usarlo come riferimento
    useImperativeHandle(ref, () => ({
      showSnackbar: (textToShow = null) => {
        if (textToShow) {
          setTextToDisplay(textToShow);
        } else {
          setTextToDisplay(null);
        }
        setShowSnackbar(true);
      },
      hideSnackbar: () => {
        setShowSnackbar(false);
      },
    }));

    return (
      <div style={extraStyle} data-testid={dataTestId} className={`hc--generic-snackbar primaryBackgroundColor ${className} ${showSnackbar ? 'showing' : 'not-showing'}`}>
        {textToDisplay !== null ? textToDisplay : children}
      </div>
    );
  }),
);

export default GenericSnackbar;
