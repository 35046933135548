export const TYPE = {
  MOOD_UPPERCASE: 'MOOD',
  SPECIFICATIONONLY_UPPERCASE: 'SPECIFICATIONONLY',
  FINISH_UPPERCASE: 'FINISH',
  OPTIONALS_UPPERCASE: 'OPTIONALS',
  MULTIFINISH_UPPERCASE: 'MULTIFINISH',
  MOOD_UPPERCASE_FIRST: 'Mood',
  SPECIFICATIONONLY_UPPERCASE_FIRST: 'SpecificationOnly',
  FINISH_UPPERCASE_FIRST: 'Finish',
  OPTIONALS_UPPERCASE_FIRST: 'Optionals',
  MULTIFINISH_UPPERCASE_FIRST: 'MultiFinish',
  MOOD: 'mood',
  SPECIFICATION: 'specification',
  SPECIFICATIONONLY: 'specificationonly',
  FINISH: 'finish',
  OPTIONAL: 'optional',
  OPTIONALS: 'optionals',
  MULTIFINISH: 'multifinish',
};

export default TYPE;
