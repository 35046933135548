import { observer, inject } from 'mobx-react';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import GenericModal from 'components/common/controls/GenericModal';

const ErrorModal = inject()(
  observer(
    forwardRef((props, ref) => {
      const modalRef = useRef(null);
      const [title, setTitle] = useState('');
      const [description, setDescription] = useState('');

      const handleShow = useCallback(() => {
        modalRef.current.showModal();
      }, []);

      const handleClose = useCallback(() => {
        modalRef.current.closeModal();
      }, []);

      useImperativeHandle(ref, () => ({
        showModal: (title, description) => {
          setTitle(title);
          setDescription(description);
          handleShow();
        },
        closeModal: () => {
          handleClose();
        },
      }));

      return (
        <GenericModal className='error-modal' ref={modalRef}>
          <div className='hc--modal-title'>{title}</div>
          <div className='hc--modal-body'>{description}</div>
        </GenericModal>
      );
    }),
  ),
);

export default ErrorModal;
