/* eslint-disable prefer-const, no-plusplus */
import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class ProjectStore {
  name = '';
  displayName = '';
  id = null;
  host = '';
  configuration = null;
  contactPhone = '';
  contactEmail = '';
  legalNotesLinks = null;
  testing = false;
  initialFlow = null;
  baseurl = undefined;
  enabledTools = null;
  locale = null;
  currency = null;
  hasError = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ProjectStore_HomeConfigurator',
      properties: ['name', 'displayName', 'id', 'host', 'configuration', 'contactPhone', 'contactEmail', 'legalNotesLinks', 'baseurl'],
      storage: window.localStorage,
    });
  }

  reset() {
    this.displayName = '';
    this.name = '';
    this.id = null;
    this.host = '';
    this.configuration = null;
    this.contactPhone = '';
    this.contactEmail = '';
    this.legalNotesLinks = null;
    this.testing = true;
    this.initialFlow = null;
    this.baseurl = undefined;
    this.enabledTools = null;
    this.locale = null;
    this.currency = null;
  }

  setProject(project) {
    this.displayName = project.displayName;
    this.name = project.displayName;
    this.id = project.id;
    this.host = project.hostKey;
    this.configuration = project.homeConfiguratorConfig;
    this.contactPhone = project.contactPhone;
    this.contactEmail = project.contactEmail;
    this.legalNotesLinks = project.legalNotesLinks;
    this.baseurl = project.baseurl || '';
    this.enabledTools = project.enabledTools;
    this.locale = project.locale;
    this.currency = project.currency;

    let nav = '';

    this.initialFlow = JSON.parse(JSON.stringify(this.configuration.flow));
    this.configuration.flow = this.configuration.flow.map((step, index) => {
      if (index > 0) nav += `/:nav${index}`;
      return {
        ...step,
        index: index + 1,
        navigation: nav,
      };
    });
  }

  setFlow(flow) {
    this.configuration.flow = flow;
  }

  updateFlowWithSections(sections) {
    let nav = '/:configuration';
    let offset = 1;
    this.configuration.flow = this.initialFlow.reduce((res, step, index) => {
      if (index > 0) nav += `/:nav${index + offset}`;

      if (step.component !== 'Finish')
        return [
          ...res,
          {
            ...step,
            index: index + offset,
            navigation: nav,
          },
        ];
      // eslint-disable-next-line
      else {
        let offsetList = 0;
        const newList = res.concat(
          sections.map((s, i) => {
            offsetList = i;
            if (i > 0) nav += `/:nav${index + offsetList + offset}`;

            return {
              ...step,
              index: index + offset + offsetList,
              navigation: nav,
              filters: [
                {
                  type: 'SECTION',
                  value: s.name,
                  code: s.code,
                },
              ],
            };
          }),
        );
        offset += offsetList;
        return newList;
      }
    }, []);
  }

  setHost(host) {
    this.host = host;
  }

  setTesting(val) {
    this.testing = val;
  }

  setHasError(error) {
    this.hasError = error;
  }

  getHasError() {
    return this.hasError;
  }

  clearProject() {
    this.name = '';
    this.id = null;
  }

  getSite(siteName) {
    return this.enabledTools.find((site) => site.name === siteName)?.url;
  }

  get getName() {
    return this.name;
  }

  get getProjectId() {
    return this.id;
  }

  get getHost() {
    return this.host;
  }

  get getConfiguration() {
    return this.configuration;
  }

  get getFlow() {
    if (this.configuration) {
      return toJS(this.configuration.flow);
    }
    return [];
  }

  get decimalSuperfici() {
    if (this.configuration?.settings?.decimalSuperfici) {
      return this.configuration.settings.decimalSuperfici;
    }
    return undefined;
  }

  get getContactEmail() {
    return this.contactEmail;
  }

  get getContactPhone() {
    return this.contactPhone;
  }

  get getLegalNotesLinks() {
    return this.legalNotesLinks;
  }

  get getSettings() {
    if (this.configuration?.settings) {
      return this.configuration.settings;
    }
    return undefined;
  }

  get enterStatusesApt() {
    if (typeof this.configuration.enterStatusesApt !== 'undefined') {
      return this.configuration.enterStatusesApt;
    }
    return [];
  }

  get getProjectAssetFolder() {
    return this.displayName;
  }

  get getLocale() {
    return this.locale;
  }

  get getCurrency() {
    return this.currency;
  }
}

const projectStore = new ProjectStore();
export default projectStore;
