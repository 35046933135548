import { gql } from '@apollo/client';

export const OPTIONAL_DATA_TO_RETRIEVE = `
  id
  name
  code
  price
  description
  optionType
`;

export const GET_ALL_OPTIONALS = gql`
  query getAllOptionals($project_id :ID!) {
    getAllOptionals(project_id: $project_id) {
      ${OPTIONAL_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_ALL_OPTIONALS_OPTIONS = (projectId, skip = false) => {
  return {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};
