import TagManager from '@tecma/analytics';

const inizializeAnalytics = ({ gtmId }) => {
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
};

export default inizializeAnalytics;
