export const DetectUrl = (ProjectStore) => {
  let hostname = window.location.hostname.indexOf('www.') >= 0 ? window.location.hostname : `www.${window.location.hostname}`;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');

  if (hostname.indexOf('localhost') >= 0 || hostname.indexOf('.ddns.net') >= 0 || hostname.indexOf('biz-tecma') >= 0 || hostname.indexOf('-demo.tecmasolutions.com') >= 0) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '' && localStorage.myhome_dev_hostname !== hostnameParam) {
      localStorage.clear();
      delete localStorage.myhome_dev_hostname;
      localStorage.setItem('myhome_dev_hostname', hostnameParam);

      // CookiesUtils.vendorLogout();
      if (ProjectStore) {
        ProjectStore.clearProject();
      }
    }

    const homeConfiguratorDevHostname = localStorage.getItem('myhome_dev_hostname');

    if (homeConfiguratorDevHostname && homeConfiguratorDevHostname.length > 0 && homeConfiguratorDevHostname !== '') {
      return homeConfiguratorDevHostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default {
  DetectUrl,
};
