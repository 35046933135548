/* eslint-disable react/function-component-definition */
import React from 'react';
import GenericSnackbar from 'components/common/controls/GenericSnackbar';
import Button from 'components/common/controls/Button';
import * as serviceWorker from 'serviceWorker';
import { useTranslation } from '@tecma/i18n';
import Loader from 'components/common/Loader';

const ServiceWorkerWrapper = ({ baseUrl = '/' }) => {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);
  const [showReloading, setShowReloading] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate }, baseUrl);
  }, [baseUrl]);

  const reloadPage = () => {
    // clear all localStorage data
    delete localStorage.ClientStore_HomeConfigurator;
    delete localStorage.ProjectStore_HomeConfigurator;
    delete localStorage.UserStore_HomeConfigurator;
    delete localStorage.NavigationStore_HomeConfigurator;
    delete localStorage.QuoteStore_HomeConfigurator;
    delete localStorage.LanguageStore_HomeConfigurator;

    // signal service worker page is ready for refresh
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.postMessage('SKIP_WAITING');
      setShowReloading(true);
    }
  };

  return (
    <div>
      <GenericSnackbar className='sw-snackbar' show={showReload} extraStyle={{ width: '40vw' }}>
        <div className='snackbar-body' style={{ color: '#000000' }}>
          {!showReloading ? t('app.newVersion', { defaultValue: 'a new version available!' }) : ''}
          {showReloading ? (
            <Loader loading hasBackdrop={false} transparency />
          ) : (
            <Button className='update-button' style={{ marginLeft: '10px' }} onClick={reloadPage}>
              {t('app.update', { defaultValue: 'Update' })}
            </Button>
          )}
        </div>
      </GenericSnackbar>
    </div>
  );
};

export default ServiceWorkerWrapper;
