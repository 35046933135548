import { observer, inject } from 'mobx-react';

const Loader = inject()(
  observer(({ show = true, whiteLoader, className = '' }) => {
    return show ? (
      <div className={`loader-container ${className} ${whiteLoader ? 'white' : 'black'}`}>
        <div className='spinner'>
          <div className='bounce1' />
          <div className='bounce2' />
          <div className='bounce3' />
        </div>
      </div>
    ) : null;
  }),
);

export default Loader;
