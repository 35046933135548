import { observer, inject } from 'mobx-react';
import { ErrorPage as GenericErrorPage } from '@tecma/ds';
import { useNavigate } from 'react-router-dom';

const ErrorPage = inject('ProjectStore')(
  observer(({ ProjectStore, hasNavigate = false }) => {
    const navigate = hasNavigate ? useNavigate() : null;

    return (
      <div data-testid='error-page'>
        <GenericErrorPage
          logo={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(ProjectStore.getName)}/global/img/primary-logo.svg`}
          buttonOnClick={() => {
            if (hasNavigate) {
              navigate('/');
            } else {
              window.location.href = ProjectStore.baseurl;
            }
          }}
        />
      </div>
    );
  }),
);

export default ErrorPage;
