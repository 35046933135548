import { gql } from '@apollo/client';

const APARTMENT_DATA_TO_RETRIEVE = `
    aptId
    moods
    specifications
    optionals
    sections
`;

export const GET_APARTMENT = gql`
  query getApartmentHCV2($id: ID!) {
    getApartmentHCV2(id: $id) {
      ${APARTMENT_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_APARTMENT_OPTIONS = (apartmentId, skip = false) => {
  return {
    variables: {
      id: apartmentId,
    },
    fetchPolicy: 'network-only',
    skip,
  };
};
