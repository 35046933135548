import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class ClientStore {
  clientId = null;
  clientData = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'ClientStore_HomeConfigurator', properties: ['clientId', 'clientData'], storage: window.localStorage });
  }

  reset() {
    this.clientId = null;
    this.clientData = null;
  }

  setClientId(clientId) {
    this.clientId = clientId;
  }

  get getClientId() {
    return this.clientId;
  }

  setClientData(clientData) {
    this.clientData = clientData;
  }

  get getClientData() {
    return this.clientData;
  }
}

const clientStore = new ClientStore();
export default clientStore;
