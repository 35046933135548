import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useTranslation } from '@tecma/i18n';
import Button from 'components/common/controls/Button';
import GenericModal from 'components/common/controls/GenericModal';

const DisclaimerModal = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const modalRef = useRef(null);

  const handleShow = useCallback(() => {
    modalRef.current.showModal();
  }, []);

  const handleClose = useCallback(() => {
    modalRef.current.closeModal();
  }, []);

  useImperativeHandle(ref, () => ({
    showModal: () => {
      handleShow();
    },
    closeModal: () => {
      handleClose();
    },
  }));

  return (
    <GenericModal dataTestId='disclaimer-modal' closeTestId='disclaimer-modal-close' className='disclaimer-modal' ref={modalRef}>
      <div className='hc--modal-title'>{t('app.disclaimer', { defaultValue: 'Disclaimer' })}</div>
      <div className='hc--modal-body' data-testid='disclaimer-modal-label'>
        {t('label.disclaimerModal.illustrationPurposeOnly', {
          defaultValue:
            'Le immagini dei prodotti e/o dei materiali sono inserite esclusivamente a scopo illustrativo. Il presente documento di configurazione riepilogativo non comporta, né per l’utente né per il venditore, l’insorgere di alcun rapporto contrattuale avente ad oggetto i prodotti e/o materiali ivi inclusi.',
        })}

        <Button inverted onClick={handleClose}>
          {t('app.chiudi', { defaultValue: 'Chiudi' })}
        </Button>
      </div>
    </GenericModal>
  );
});

export default DisclaimerModal;
