import { observer, inject } from 'mobx-react';
import Link from 'components/common/controls/Link';
import DisclaimerModal from 'components/common/modals/DisclaimerModal';
import { useTranslation } from '@tecma/i18n';
import { useCallback, useRef } from 'react';
import Button from 'components/common/controls/Button';

const Footer = inject('ProjectStore')(
  observer(({ ProjectStore }) => {
    const { t } = useTranslation();

    const links = ProjectStore.getLegalNotesLinks;
    const DEFAULT_LINK = `http://${ProjectStore.getHost}/note-legali.html`;
    const cookies = links && links.cookies ? links.cookies : DEFAULT_LINK;
    const privacy = links && links.privacy ? links.privacy : DEFAULT_LINK;
    const disclaimerModalRef = useRef(null);
    const handleClickDisclaimer = useCallback(() => {
      if (disclaimerModalRef) {
        disclaimerModalRef.current.showModal();
      }
    }, []);

    return (
      <>
        <DisclaimerModal ref={disclaimerModalRef} />
        <div className='hc--footer' data-testid='hc-footer'>
          <Button className='hc--link' onClick={handleClickDisclaimer} dataTestId='footer-disclaimer' style={{ padding: '0', border: 'none' }}>
            {t('app.disclaimer', { defaultValue: 'Disclaimer' })}
          </Button>

          <Link href={cookies} dataTestId='hc-footer-cookies'>
            {t('app.cookiePolicy', { defaultValue: 'Cookie Policy' })}
          </Link>

          <Link href={privacy} dataTestId='hc-footer-privacy'>
            {t('app.privacy', { defaultValue: 'Privacy' })}
          </Link>
        </div>
      </>
    );
  }),
);

export default Footer;
