import { useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';
import GRAPHQL from 'graphql/GraphQLEndpoints';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import CookiesUtils from 'utils/cookiesUtils';
import { buildToolLink } from './utils';

const AuthChecker = inject(
  'ProjectStore',
  'UserStore',
  'ClientStore',
  'LanguageStore',
)(
  observer(({ ProjectStore, UserStore, ClientStore, LanguageStore }) => {
    const [loadUserInfo, userInfo] = useLazyQuery(GRAPHQL.GET_USER_INFO);
    const [logoutMutation] = useMutation(GRAPHQL.LOGOUT);
    const [updateUserLanguage] = useMutation(GRAPHQL.UPDATE_USER_LANGUAGE);
    const [initialCall, setInitialCall] = useState(true);
    const { i18n } = useTranslation();

    const logout = useCallback(() => {
      logoutMutation().then((res) => {
        if (res.data && res.data.logout === 'Ok') {
          UserStore.logOut();
          CookiesUtils.removeClientId();

          // Redirect To My Home
          const MY_HOME_LINK = buildToolLink('hc', 'myhome', ProjectStore.getHost, ProjectStore.getSite('MyHome'));
          window.location.assign(MY_HOME_LINK);
        }
      });
    }, [UserStore, ProjectStore, logoutMutation]);

    const checkUserData = useCallback(() => {
      if (!CookiesUtils.getClientId() || (ClientStore.getClientId && CookiesUtils.getClientId() !== ClientStore.getClientId)) {
        // Logout, different client
        logout();
      }
      UserStore.setCheckingJwt(true);
      if (ProjectStore.getProjectId) loadUserInfo(GRAPHQL.GET_USER_INFO_DEFAULT_OPTIONS_V2(ProjectStore.getProjectId)); // Update User Info
    }, [UserStore, ProjectStore.getProjectId, ClientStore.getClientId, logout, loadUserInfo]);

    useEffect(() => {
      if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
        if (UserStore.loggedUser && UserStore.loggedUser.id !== userInfo.data.getUserByJWT.id) {
          logout();
        } else {
          UserStore.setLoggedUser(userInfo.data.getUserByJWT);
          const userLanguage = userInfo.data.getUserByJWT.language;
          if (userLanguage) {
            let language = null;
            const userLocale = userInfo.data.getUserByJWT.locale;
            if (userLocale) language = `${userLanguage}-${userLocale}`;
            else language = LanguageStore.closestLanguage(userLanguage);

            runInAction(() => {
              if (language) LanguageStore.setUserLanguage(language, false);
            });
          }
        }
      }
      UserStore.setCheckingJwt(false);
      // eslint-disable-next-line
    }, [UserStore, userInfo]);

    const handleVisibilityChange = useCallback(() => {
      if (!document.hidden) {
        checkUserData();
      }
    }, [checkUserData]);

    useEffect(() => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [handleVisibilityChange]);

    useEffect(() => {
      if (ProjectStore.getProjectId && initialCall && LanguageStore.languagesInitialized) {
        setInitialCall(false);
        LanguageStore.setLanguageChangeHandler((lang, update) => {
          i18n.changeLanguage(lang);
          if (update) {
            const [language, locale] = lang.split(/[-_]/);
            updateUserLanguage(GRAPHQL.UPDATE_USER_LANGUAGE_DEFAULT_OPTIONS(UserStore.getLoggedUser.id, ProjectStore.getProjectId, language, locale)).catch((err) => {
              console.error(err);
            });
          }
        });
        checkUserData();
      }
      // eslint-disable-next-line
    }, [ProjectStore.getProjectId, LanguageStore.languagesInitialized]);

    return '';
  }),
);

export default AuthChecker;
